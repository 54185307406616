<template>
  <div class="footer">
    <v-footer color="transparent" class="py-8 body-2" inset app absolute>
      <div class="grey--text">
        <div class="mr-10">Version {{ appVersion }} (Beta)</div>
        <div>
          <a
            href="https://docs.mira-one.com/impressum"
            class="grey--text text-decoration-none"
            target="_blank"
            rel="noopener noreferrer"
            >Impressum</a
          >
          <span> – </span>
          <a
            href="https://docs.mira-one.com/datenschutz"
            class="grey--text text-decoration-none"
            target="_blank"
            rel="noopener noreferrer"
            >Datenschutzerklärung</a
          >
          <span> – </span>
          <!-- <a
            href="https://docs.mira-one.com/cookies"
            class="grey--text text-decoration-none"
            target="_blank"
            rel="noopener noreferrer"
            >Verwendung von Cookies</a
          >
          <span> – </span> -->
          <a
            href="https://docs.mira-one.com/agb"
            class="grey--text text-decoration-none"
            target="_blank"
            rel="noopener noreferrer"
            >AGB</a
          >
        </div>
        <v-spacer></v-spacer>
        <div>
          &copy; {{ new Date().getFullYear() }} Alle Rechte vorbehalten.
          Mira&nbsp;Digital&nbsp;Solutions&nbsp;UG&nbsp;(haftungsbeschränkt)
        </div>
      </div>
    </v-footer>
  </div>
</template>

<style></style>

<script>
export default {
  data() {
    return {
      appVersion: "0.1.4.9",
    };
  },
  // computed: {
  //   appVersion() {
  //     return process.env.VUE_APP_VERSION
  //   }
  // },
};
</script>
