import {
  ACCOUNTING_TIME_SHEETS_read,
  ACCOUNTING_TIME_SHEETS_create,
  ACCOUNTING_TIME_SHEETS_update,
} from "@/data/permission-types.js";
export const accounting = {
  path: "accounting",
  name: "accounting",
  component: () =>
    import(
      /* webpackChunkName: "accounting" */ "../../views/accounting/AccountingIndex.vue"
    ),
  meta: {
    requiresAuth: true,
    requiresPermission: true,
  },
  children: [
    // accounting > bookkeeping
    {
      path: "time-sheets",
      name: "accounting-time-sheets",
      component: () =>
        import(
          /* webpackChunkName: "accounting-time-sheets" */ "../../views/accounting/AccountingTimeSheets.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${ACCOUNTING_TIME_SHEETS_read}`,
      },
    },
    {
      path: "time-sheets/new",
      name: "accounting-time-sheets-new",
      component: () =>
        import(
          /* webpackChunkName: "accounting-time-sheets-manage" */ "../../views/accounting/time-sheets/TimeSheetNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${ACCOUNTING_TIME_SHEETS_create}`,
      },
    },
    {
      path: "time-sheets/:itemId/edit",
      name: "accounting-time-sheets-edit",
      component: () =>
        import(
          /* webpackChunkName: "accounting-time-sheets-manage" */ "../../views/accounting/time-sheets/TimeSheetEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${ACCOUNTING_TIME_SHEETS_update}`,
      },
    },
    {
      path: "time-sheets/:itemId",
      name: "accounting-time-sheets-details",
      component: () =>
        import(
          /* webpackChunkName: "accounting-time-sheets-details" */ "../../views/accounting/time-sheets/TimeSheetDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        permissionID: `${ACCOUNTING_TIME_SHEETS_read}`,
      },
    },
    // accounting > accounting
    {
      path: "payroll",
      name: "accounting-payroll",
      component: () =>
        import(
          /* webpackChunkName: "accounting-payroll" */ "../../views/accounting/AccountingPayroll.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        // permissionID: `${ACCOUNTING_PAYROLL_read}`,
      },
    },
    {
      path: "payroll/new",
      name: "accounting-payroll-new",
      component: () =>
        import(
          /* webpackChunkName: "accounting-payroll-manage" */ "../../views/accounting/payroll/PayrollNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        // permissionID: `${ACCOUNTING_PAYROLL_create}`,
      },
    },
    // {
    //   path: "payroll/:itemId/edit",
    //   name: "accounting-payroll-edit",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "accounting-payroll-manage" */ "../../views/accounting/payroll/PayrollEdit.vue"
    //     ),
    //   meta: {
    //     requiresAuth: true,
    //     requiresPermission: true,
    //     // permissionID: `${ACCOUNTING_PAYROLL_update}`,
    //   },
    // },
    // {
    //   path: "payroll/:itemId",
    //   name: "accounting-payroll-details",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "accounting-payroll-manage" */ "../../views/accounting/payroll/PayrollDetails.vue"
    //     ),
    //   meta: {
    //     requiresAuth: true,
    //     requiresPermission: true,
    //     // permissionID: `${ACCOUNTING_REQUESTS_read}`,
    //   },
    // },
    // accounting > reports
    {
      path: "reports",
      name: "accounting-reports",
      component: () =>
        import(
          /* webpackChunkName: "accounting-reports" */ "../../views/accounting/AccountingReports.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        // permissionID: `${ACCOUNTING_PAYROLL_read}`,
      },
    },
    // accounting > config
    {
      path: "config",
      name: "accounting-config",
      component: () =>
        import(
          /* webpackChunkName: "accounting-config" */ "../../views/accounting/AccountingConfig.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        // permissionID: `${ACCOUNTING_PAYROLL_read}`,
      },
      children: [
        {
          path: "general",
          name: "accounting-config-tab-general",
          component: () =>
            import(
              /* webpackChunkName: "accounting-config" */ "../../views/accounting/config/ConfigTabGeneral.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            // permissionID: `${ACCOUNTING_CONFIG_GENERAL_read}`,
          },
        },
        {
          path: "payroll-types",
          name: "accounting-config-tab-payroll-types",
          component: () =>
            import(
              /* webpackChunkName: "accounting-config" */ "../../views/accounting/config/ConfigTabPayrollTypes.vue"
            ),
          meta: {
            requiresAuth: true,
            requiresPermission: true,
            // permissionID: `${ACCOUNTING_CONFIG_BILLING_TYPES_read}`,
          },
        },
      ],
    },
    {
      path: "config/payroll-types/new",
      name: "accounting-config-tab-payroll-types-payroll-type-new",
      component: () =>
        import(
          /* webpackChunkName: "accounting-config" */ "../../views/accounting/config/payroll-types/PayrollTypeNew.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        // permissionID: `${ACCOUNTING_CONFIG_REQUEST_TYPES_create}`,
      },
    },
    {
      path: "config/payroll-types/:itemId",
      name: "accounting-config-tab-payroll-types-payroll-type-details",
      component: () =>
        import(
          /* webpackChunkName: "accounting-config" */ "../../views/accounting/config/payroll-types/PayrollTypeDetails.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        // permissionID: `${ACCOUNTING_CONFIG_REQUEST_TYPES_read}`,
      },
    },
    {
      path: "config/payroll-types/:itemId/edit",
      name: "accounting-config-tab-payroll-types-payroll-type-edit",
      component: () =>
        import(
          /* webpackChunkName: "accounting-config" */ "../../views/accounting/config/payroll-types/PayrollTypeEdit.vue"
        ),
      meta: {
        requiresAuth: true,
        requiresPermission: true,
        // permissionID: `${ACCOUNTING_CONFIG_REQUEST_TYPES_update}`,
      },
    },
  ],
};
