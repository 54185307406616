<template>
  <div v-if="showNavigation">
    <v-navigation-drawer
      v-if="showNavigationDrawer"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      width="315"
      fixed
      app
      :expand-on-hover="miniMode"
      :mini-variant="miniMode"
      :right="right"
    >
      <v-list nav>
        <organization-info-list-item class="mb-2"></organization-info-list-item>
        <v-divider
          v-if="organizationInTrial && !trialCardIsHidden"
          class="mb-2"
        ></v-divider>
        <trial-phase-info-card
          v-if="organizationInTrial && !trialCardIsHidden"
        ></trial-phase-info-card>
        <v-divider class="mb-2"></v-divider>
        <div v-if="!organizationInMaintenance">
          <template v-for="item in filteredPages">
            <v-list-group
              v-if="item.children"
              :key="item.name"
              :prepend-icon="item.icon"
              append-icon="mdi-chevron-down"
              class="nav"
              link
            >
              <!-- START: List item for link without prepended icon -->
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title> {{ item.name }} </v-list-item-title>
                </v-list-item-content>
              </template>
              <!-- END: List item for link without prepended icon -->
              <v-list-item
                v-for="(child, index) in item.children"
                :key="index"
                link
                :to="child.route"
              >
                <!-- v-list for items with children -->
                <v-list-item-action v-if="child.icon">
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2 font-weight-medium">
                    {{ child.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <!-- v-list for items without children -->
            <v-list-item
              v-else
              :key="item.id"
              :to="item.route"
              link
              active-class="primary--text"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  :class="$vuetify.theme.dark ? 'white--text' : ''"
                >
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </div>
        <v-sheet height="80px" color="transparent"></v-sheet>
      </v-list>
      <v-btn
        class="ml-2 hidden-md-and-down"
        style="position: absolute; bottom: 10px"
        fab
        small
        depressed
        color="$vuetify.theme.dark ? '#333333' : 'white'"
        @click="miniMode = !miniMode"
        ><v-icon>{{
          miniMode ? "mdi-page-last" : "mdi-page-first"
        }}</v-icon></v-btn
      >
    </v-navigation-drawer>
  </div>
</template>

<script>
// import {
//   ADMIN,
//   ORGANISATION,
//   SCHEDULING,
//   MY_ORGANIZATION,
// } from "@/store/modules.js";
// import {
//   RESET_ADMIN,
//   RESET_ORGANISATION,
//   RESET_SCHEDULING,
//   RESET_MY_ORGANIZATION,
// } from "@/store/action-types.js";
import {
  OWNER_ACCESS,
  ADMIN_ACCESS,
  SUPPORT_ACCESS,
} from "@/data/permission-types.js";
import { modules } from "@/data/app-modules.js";
// import OptionsMenu from "@/components/_system/helpers/OptionsMenu.vue";
import OrganizationInfoListItem from "@/components/organization/OrganizationInfoListItem.vue";
import TrialPhaseInfoCard from "@/components/admin/billing/TrialPhaseInfoCard.vue";

export default {
  name: "navigation-drawer",
  components: {
    // OptionsMenu,
    OrganizationInfoListItem,
    TrialPhaseInfoCard,
  },
  props: {
    right: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: true,
      miniMode: false,
      items: modules,
    };
  },
  computed: {
    showNavigation() {
      let disabledOnPages = [
        "maintenance",
        "auth-login",
        "auth-register",
        "setup-verify-email",
      ];
      if (disabledOnPages.indexOf(this.$route.name) == -1) {
        return true;
      } else {
        return false;
      }
    },
    showNavigationDrawer() {
      let disabledOnPages = [
        // directly in modules
        "scheduling-event-details-manage-responses",
        "accounting-payroll-new",
        "accounting-payroll-edit",
        // "duty-management-reports-new",
        "duty-management-reports-live",

        // other
        "setup-onboarding",
        "organization-list",
        "organization-new",
        "notification-center",
        "settings",
        "settings-profile",
        "settings-organizations",
        "settings-appearance",
        "settings-accessability",
        "settings-contact",
        "settings-notifications",
        "settings-privacy",
        "settings-contact",
        "settings-emails",
        "settings-security",
        "organization-join",
        "not-found",
        "error",
      ];
      if (disabledOnPages.indexOf(this.$route.name) == -1) {
        return true;
      } else {
        return false;
      }
    },
    trialCardIsHidden() {
      return this.$store.getters["organization/checkFeatureAvailabilityById"](
        "hideTrialInfo"
      );
    },
    organizationInMaintenance() {
      if (this.routeIsOrg == null || false) {
        return false;
      } else if (this.organization) {
        return this.$store.state.organization.activeOrganization.config
          .inMaintenance;
      } else {
        return false;
      }
    },
    organizationInTrial() {
      if (this.routeIsOrg == null || false) {
        return false;
      } else if (this.organization) {
        return this.$store.state.organization.activeOrganization.billing.trial
          .isActive;
      } else {
        return false;
      }
    },
    routeIsOrg() {
      return this.$route.matched.some(
        ({ name }) => name === "organization-detail"
      );
    },
    organization() {
      if (this.routeIsOrg == null || false) {
        return null;
      } else {
        return this.$store.state.organization.activeOrganization;
      }
    },
    userPermissions() {
      // if (this.organization == null || {}) {
      //   return [];
      // } else {
      return this.$store.state.organization.membership.access.permissions
        .allPermissionsIds;
      // }
    },
    activatedModules() {
      // if (this.organization == null || {}) {
      //   return [];
      // } else {
      const activatedModules =
        this.$store.state.organization.activeOrganization.config.extensions
          .activated;
      if (!activatedModules.length) {
        return [];
      } else {
        return activatedModules;
      }
      // }
    },
    filteredPages() {
      if (this.organization == null) {
        return [];
      } else {
        var permissions = this.userPermissions;
        if (
          permissions.includes(`${ADMIN_ACCESS}`) ||
          permissions.includes(`${OWNER_ACCESS}`) ||
          permissions.includes(`${SUPPORT_ACCESS}`)
        ) {
          return this.items
            .map((page) => ({
              ...page,
              children: page.children
                ? // when children is truthy
                  page.children.filter(
                    // filter out those not in `activatedModules`
                    (child) => this.activatedModules.includes(child.id)
                  )
                : page.children,
            }))
            .filter(
              // only keep page if in `activatedModules` and...
              (page) =>
                this.activatedModules.includes(page.id) &&
                // if children is truthy and has length or...
                (page.children?.length ||
                  // if children is falsy and page.permissions in userPermissions
                  !page.children) /*&&
                    this.userPermissions.includes(page.permissions)*/
            );
        } else {
          return this.items
            .map((page) => ({
              ...page,
              children: page.children
                ? // when children is truthy
                  page.children.filter(
                    // filter out those not in `userPermissions`
                    (child) =>
                      this.userPermissions.includes(child.permissions) &&
                      // and those not in `activatedModules`
                      this.activatedModules.includes(child.id)
                  )
                : page.children,
            }))
            .filter(
              // only keep page if in `activatedModules` and...
              (page) =>
                this.activatedModules.includes(page.id) &&
                // if children is truthy and has length or...
                (page.children?.length ||
                  // if children is falsy and page.permissions in userPermissions
                  (!page.children &&
                    this.userPermissions.includes(page.permissions)))
            );
        }
      }
    },
  },
  // beforeRouteUpdate (to, from, next) {
  //   this.items[0].children[0].route = null;
  //   this.test(to.params.id, 'toto')
  //   next()
  // },
  // created() {
  //   console.log(
  //     "-- START --",
  //     "\n",
  //     "\n",
  //     this.userPermissions,
  //     "\n",
  //     this.activatedModules,
  //     "\n",
  //     "\n",
  //     "-- ENDE --"
  //   );

  //   console.log("userPermissions", this.userPermissions);
  //   console.log("activatedModules", this.activatedModules);
  // },
  methods: {
    changeKey() {
      this.key = this.key + 1;
    },
  },
};
</script>
