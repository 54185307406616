// These mutations are triggered by actions and mutate the state of Vuex

// Global
export const SET_LOADING = "SET_LOADING";

// user.js
export const SET_USER_IS_AUTHENTICATED = "SET_USER_IS_AUTHENTICATED";
export const SET_USER_AUTH_CHECK_STATUS = "SET_USER_AUTH_CHECK_STATUS";
export const SET_USER = "SET_USER";
export const SET_MEMBERSHIPS = "SET_MEMBERSHIPS";
export const SET_PERMISSIONS = "SET_PERMISSIONS";

// organisation.js
export const SET_ORGANISATION = "SET_ORGANISATION";
export const SET_MEMBERSHIP = "SET_MEMBERSHIP";

// module/...
// module/my-organization.js
// Dashboard
export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA";
// News
export const SET_NEWS = "SET_NEWS";
export const SET_NEWS_INBOUND_EMAILS = "SET_NEWS_INBOUND_EMAILS";
// Guardbook
export const SET_GUARDBOOK_ENTRIES = "SET_GUARDBOOK_ENTRIES";
export const SET_GUARDBOOK_CATEGORIES = "SET_GUARDBOOK_CATEGORIES";
// Surveys
export const SET_SURVEYS = "SET_SURVEYS";
// Assignments
export const SET_ASSIGNMENTS = "SET_ASSIGNMENTS";
// Teams
export const SET_TEAMS = "SET_TEAMS";
export const SET_KATEGORIEN = "SET_KATEGORIEN";

// module/scheduling.js
export const SET_EVENTS = "SET_EVENTS";
export const SET_ARCHIVE = "SET_ARCHIVE";
export const SET_CALENDAR_FEEDS = "SET_CALENDAR_FEEDS";
export const SET_EVENTS_ISO = "SET_EVENTS_ISO";
export const SET_EVENTS_STATUS = "SET_EVENTS_STATUS";
export const SET_EVENT_TYPES = "SET_EVENT_TYPES";
export const SET_EVENT_TEMPLATES = "SET_EVENT_TEMPLATES";

// module/duty-management.js
export const SET_REPORTS = "SET_REPORTS";
export const SET_ARCHIVED_REPORTS = "SET_ARCHIVED_REPORTS";
export const SET_REPORT_TYPES = "SET_REPORT_TYPES";

// module/availability.js
export const SET_LEAVES = "SET_LEAVES";
export const SET_REQUESTS = "SET_REQUESTS";
export const SET_ARCHIVED_LEAVES = "SET_ARCHIVED_LEAVES";
export const SET_ARCHIVED_REQUESTS = "SET_ARCHIVED_REQUESTS";
export const SET_UNITS = "SET_UNITS";
export const SET_POSITIONS = "SET_POSITIONS";
export const SET_REQUEST_TYPES = "SET_REQUEST_TYPES";
export const SET_REQUESTS_STATUS = "SET_REQUEST_STATUS";

// module/member-management.js
export const SET_PERSONNEL_RECORDS = "SET_PERSONNEL_RECORDS";
export const SET_DEADLINES = "SET_DEADLINES";
export const SET_PERFORMANCE_TARGETS = "SET_PERFORMANCE_TARGETS";
export const SET_SKILLS = "SET_SKILLS";
export const SET_SKILL_GROUPS = "SET_SKILL_GROUPS";
export const SET_COURSES = "SET_COURSES";
export const SET_COURSE_GROUPS = "SET_COURSE_GROUPS";
export const SET_RANKS = "SET_RANKS";
export const SET_DUTY_POSITIONS = "SET_DUTY_POSITIONS";
export const SET_HONORS = "SET_HONORS";
export const SET_DIVISIONS = "SET_DIVISIONS";
export const SET_CUSTOM_FIELDS = "SET_CUSTOM_FIELDS";

// module/accounting.js
export const SET_TIME_SHEETS = "SET_TIME_SHEETS";
export const SET_BILLING_TYPES = "SET_BILLING_TYPES";

// module/finance.js

// module/admin/..

export const SET_USER_LIST = "SET_USER_LIST";
export const SET_MEMBERSHIPS_PRIVATE = "SET_MEMBERSHIPS_PRIVATE";
export const SET_INVITES = "SET_INVITES";
export const SET_USER_GROUPS = "SET_USER_GROUPS";
export const SET_STANDARD_USER_GROUP = "SET_STANDARD_USER_GROUP";
export const SET_ROLES = "SET_ROLES";
export const SET_LOGS = "SET_LOGS";
export const SET_PRIVACY_POLICY_VERSIONS = "SET_PRIVACY_POLICY_VERSIONS";
