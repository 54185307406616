// Available permissions – Mira One

// General access
export const OWNER_ACCESS = "owner";
export const ADMIN_ACCESS = "admin";
export const GUEST_ACCESS = "guest";
export const SUPPORT_ACCESS = "support";

export const SELF_SERVICE_read = "0.sel.re";

// Meine Organisation
export const MY_ORGANIZATION = "1";
// Dashboard
export const MY_ORGANIZATION_DASHBOARD_read = "1.das.re";
// Units
export const MY_ORGANIZATION_UNITS_read = "1.uni.re";
// News
export const MY_ORGANIZATION_NEWS_create = "1.new.cr";
export const MY_ORGANIZATION_NEWS_read = "1.new.re";
export const MY_ORGANIZATION_NEWS_update = "1.new.up";
export const MY_ORGANIZATION_NEWS_delete = "1.new.de";
// Surveys
export const MY_ORGANIZATION_SURVEYS_create = "1.sur.cr";
export const MY_ORGANIZATION_SURVEYS_read = "1.sur.re";
export const MY_ORGANIZATION_SURVEYS_update = "1.sur.up";
export const MY_ORGANIZATION_SURVEYS_delete = "1.sur.de";

// Guardbook
// Entries
export const MY_ORGANIZATION_GUARDBOOK_ENTRIES_create = "1.gua.e.cr";
export const MY_ORGANIZATION_GUARDBOOK_ENTRIES_read = "1.gua.e.re";
export const MY_ORGANIZATION_GUARDBOOK_ENTRIES_update = "1.gua.e.up";
export const MY_ORGANIZATION_GUARDBOOK_ENTRIES_delete = "1.gua.e.de";
// Categories
export const MY_ORGANIZATION_GUARDBOOK_CATEGORIES_create = "1.gua.c.cr";
export const MY_ORGANIZATION_GUARDBOOK_CATEGORIES_read = "1.gua.c.re";
export const MY_ORGANIZATION_GUARDBOOK_CATEGORIES_update = "1.gua.c.up";
export const MY_ORGANIZATION_GUARDBOOK_CATEGORIES_delete = "1.gua.c.de";

// Assignments
export const MY_ORGANIZATION_ASSIGNMENTS_read = "1.ass.re";
// Organizational Chart
export const MY_ORGANIZATION_ORGANIZATIONAL_CHART_read = "1.org.re";
// Teams
export const MY_ORGANIZATION_TEAMS_read = "1.tea.re";
// // Teams
export const MY_ORGANIZATION_TEAMS_TEAMS_create = "1.tea.t.cr";
export const MY_ORGANIZATION_TEAMS_TEAMS_read = "1.tea.t.re";
export const MY_ORGANIZATION_TEAMS_TEAMS_update = "1.tea.t.up";
export const MY_ORGANIZATION_TEAMS_TEAMS_delete = "1.tea.t.de";
// Kategorien
export const MY_ORGANIZATION_TEAMS_CATEGORIES_create = "1.tea.k.cr";
export const MY_ORGANIZATION_TEAMS_CATEGORIES_read = "1.tea.k.re";
export const MY_ORGANIZATION_TEAMS_CATEGORIES_update = "1.tea.k.up";
export const MY_ORGANIZATION_TEAMS_CATEGORIES_delete = "1.tea.k.de";
// Konfiguration
export const MY_ORGANIZATION_CONFIG_read = "1.conf.re";

// Terminplanung
export const SCHEDULING = "2";
// Veranstaltungen
export const SCHEDULING_EVENTS_create = "2.eve.cr";
export const SCHEDULING_EVENTS_read = "2.eve.re";
export const SCHEDULING_EVENTS_update = "2.eve.up";
export const SCHEDULING_EVENTS_delete = "2.eve.de";
export const SCHEDULING_EVENTS_RESPONSES_OWN_update = "2.eve.egr";
export const SCHEDULING_EVENTS_RESPONSES_OTHER_read = "2.eve.fgr.re";
export const SCHEDULING_EVENTS_RESPONSES_write = "2.eve.fgr.wr";

// Archiv
export const SCHEDULING_ARCHIVE_read = "2.arc.re";
// Kalender
export const SCHEDULING_CALENDAR_read = "2.cal.re";
export const SCHEDULING_CALENDAR_FEEDS_read = "2.cal.fee.re";

// Teamplaner
export const SCHEDULING_TEAMPLANNER_read = "2.tea.re";
// Konfiguration
export const SCHEDULING_CONFIG_read = "2.conf.re";
//Allgemein
export const SCHEDULING_CONFIG_GENERAL_read = "2.conf.gen.re";
export const SCHEDULING_CONFIG_GENERAL_update = "2.conf.gen.up";
// Veranstaltungsarten
export const SCHEDULING_CONFIG_EVENT_TYPES_create = "2.conf.ty.cr";
export const SCHEDULING_CONFIG_EVENT_TYPES_read = "2.conf.ty.re";
export const SCHEDULING_CONFIG_EVENT_TYPES_update = "2.conf.ty.up";
export const SCHEDULING_CONFIG_EVENT_TYPES_delete = "2.conf.ty.de";
// Vorlagen
export const SCHEDULING_CONFIG_EVENT_TEMPLATES_create = "2.conf.te.cr";
export const SCHEDULING_CONFIG_EVENT_TEMPLATES_read = "2.conf.te.re";
export const SCHEDULING_CONFIG_EVENT_TEMPLATES_update = "2.conf.te.up";
export const SCHEDULING_CONFIG_EVENT_TEMPLATES_delete = "2.conf.te.de";

// Dienstverwaltung
export const DUTY_MANAGEMENT = "3";
// Dienste / Erfassung

// Berichte
export const DUTY_MANAGEMENT_REPORTS_create = "3.rep.cr";
export const DUTY_MANAGEMENT_REPORTS_read = "3.rep.re";
export const DUTY_MANAGEMENT_REPORTS_update = "3.rep.up";
export const DUTY_MANAGEMENT_REPORTS_delete = "3.rep.de";
// Archiv
export const DUTY_MANAGEMENT_ARCHIVE_read = "3.arc.re";
// Konfiguration
export const DUTY_MANAGEMENT_CONFIG_read = "3.conf.re";
export const DUTY_MANAGEMENT_CONFIG_update = "3.conf.up";

export const DUTY_MANAGEMENT_CONFIG_REPORT_TYPES_create = "3.conf.rty.cr";
export const DUTY_MANAGEMENT_CONFIG_REPORT_TYPES_read = "3.conf.rty.re";
export const DUTY_MANAGEMENT_CONFIG_REPORT_TYPES_update = "3.conf.rty.up";
export const DUTY_MANAGEMENT_CONFIG_REPORT_TYPES_delete = "3.conf.rty.de";

// Availability
export const AVAILABILITY = "4";
// Leaves
export const AVAILABILITY_LEAVES_create = "4.lea.cr";
export const AVAILABILITY_LEAVES_read = "4.lea.re";
export const AVAILABILITY_LEAVES_update = "4.lea.up";
export const AVAILABILITY_LEAVES_delete = "4.lea.de.ow";
export const AVAILABILITY_LEAVES_read_all = "4.lea.rea";
export const AVAILABILITY_LEAVES_manage_all = "4.lea.ma";

// Einsatzmittel
export const AVAILABILITY_UNITS_create = "4.uni.cr";
export const AVAILABILITY_UNITS_read = "4.uni.re";
export const AVAILABILITY_UNITS_update = "4.uni.up";
export const AVAILABILITY_UNITS_delete = "4.uni.de";
// Funktionen
export const AVAILABILITY_POSITIONS_create = "4.pos.cr";
export const AVAILABILITY_POSITIONS_read = "4.pos.re";
export const AVAILABILITY_POSITIONS_update = "4.pos.up";
export const AVAILABILITY_POSITIONS_delete = "4.pos.de";

// Forecasts
export const AVAILABILITY_FORECASTS_read = "4.for.re";
// Requests
export const AVAILABILITY_REQUESTS_create = "4.req.cr";
export const AVAILABILITY_REQUESTS_read = "4.req.re";
export const AVAILABILITY_REQUESTS_update = "4.req.up";
export const AVAILABILITY_REQUESTS_delete = "4.req.de";
export const AVAILABILITY_REQUESTS_RESPONSES_OWN_update = "4.req.egr";
export const AVAILABILITY_REQUESTS_RESPONSES_OTHER_read = "4.req.fgr.re";
export const AVAILABILITY_REQUESTS_RESPONSES_write = "4.req.fgr.wr";
// Archive
export const AVAILABILITY_ARCHIVE_read = "4.arc.re";
// Config
export const AVAILABILITY_CONFIG_read = "4.conf.re";
export const AVAILABILITY_CONFIG_update = "4.conf.up";

//Allgemein
export const AVAILABILITY_CONFIG_GENERAL_read = "4.conf.gen.re";
export const AVAILABILITY_CONFIG_GENERAL_update = "4.conf.gen.up";

export const AVAILABILITY_CONFIG_REQUEST_TYPES_create = "4.conf.rty.cr";
export const AVAILABILITY_CONFIG_REQUEST_TYPES_read = "4.conf.rty.re";
export const AVAILABILITY_CONFIG_REQUEST_TYPES_update = "4.conf.rty.up";
export const AVAILABILITY_CONFIG_REQUEST_TYPES_delete = "4.conf.rty.de";

// Mitgliederverwaltung
// Personal
export const MEMBER_MANAGEMENT_PERSONNEL_RECORDS_create = "5.per.cr";
export const MEMBER_MANAGEMENT_PERSONNEL_RECORDS_GENERAL_read = "5.per.ge.re";
export const MEMBER_MANAGEMENT_PERSONNEL_RECORDS_GENERAL_update = "5.per.up";
export const MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CONTACT_read = "5.per.co.re";
export const MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CONTACT_update = "5.per.co.up";
export const MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CHRONICLE_read = "5.per.ch.re";
export const MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CHRONICLE_update =
  "5.per.ch.up";
export const MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CERTIFICATES_read =
  "5.per.ce.re";
export const MEMBER_MANAGEMENT_PERSONNEL_RECORDS_CERTIFICATES_update =
  "5.per.ce.up";
export const MEMBER_MANAGEMENT_PERSONNEL_RECORDS_EMERGENCY_CONTACTS_read =
  "5.per.em.re";
export const MEMBER_MANAGEMENT_PERSONNEL_RECORDS_EMERGENCY_CONTACTS_update =
  "5.per.em.up";
export const MEMBER_MANAGEMENT_PERSONNEL_RECORDS_YOUTH_GROUP_read =
  "5.per.yo.re";
export const MEMBER_MANAGEMENT_PERSONNEL_RECORDS_YOUTH_GROUP_update =
  "5.per.yo.up";
export const MEMBER_MANAGEMENT_PERSONNEL_RECORDS_ACCOUNTING_read =
  "5.per.ac.re";
export const MEMBER_MANAGEMENT_PERSONNEL_RECORDS_ACCOUNTING_update =
  "5.per.ac.up";
export const MEMBER_MANAGEMENT_PERSONNEL_RECORDS_OTHER_read = "5.per.ot.re";
export const MEMBER_MANAGEMENT_PERSONNEL_RECORDS_OTHER_update = "5.per.ot.up";
export const MEMBER_MANAGEMENT_PERSONNEL_RECORDS_delete = "5.per.de";
// Fristenüberwachung
export const MEMBER_MANAGEMENT_DEADLINES_create = "5.dea.cr";
export const MEMBER_MANAGEMENT_DEADLINES_read = "5.dea.re";
export const MEMBER_MANAGEMENT_DEADLINES_update = "5.dea.up";
export const MEMBER_MANAGEMENT_DEADLINES_delete = "5.dea.de";

// Zielvorgaben
export const MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_create = "5.per.cr";
export const MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_read = "5.per.re";
export const MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_update = "5.per.up";
export const MEMBER_MANAGEMENT_PERFORMANCE_TARGETS_delete = "5.per.de";

// Aufgabenverteilung
export const MEMBER_MANAGEMENT_ASSIGNMENTS_create = "5.ass.cr";
export const MEMBER_MANAGEMENT_ASSIGNMENTS_read = "5.ass.re";
export const MEMBER_MANAGEMENT_ASSIGNMENTS_update = "5.ass.up";
export const MEMBER_MANAGEMENT_ASSIGNMENTS_delete = "5.ass.de";

// Lehrgänge
export const MEMBER_MANAGEMENT_COURSES_create = "5.cou.cr";
export const MEMBER_MANAGEMENT_COURSES_read = "5.cou.re";
export const MEMBER_MANAGEMENT_COURSES_update = "5.cou.up";
export const MEMBER_MANAGEMENT_COURSES_delete = "5.cou.de";

// Qualifikationen
export const MEMBER_MANAGEMENT_SKILLS_create = "5.ski.cr";
export const MEMBER_MANAGEMENT_SKILLS_read = "5.ski.re";
export const MEMBER_MANAGEMENT_SKILLS_update = "5.ski.up";
export const MEMBER_MANAGEMENT_SKILLS_delete = "5.ski.de";

// Dienstgrade
export const MEMBER_MANAGEMENT_RANKS_create = "5.ran.cr";
export const MEMBER_MANAGEMENT_RANKS_read = "5.ran.re";
export const MEMBER_MANAGEMENT_RANKS_update = "5.ran.up";
export const MEMBER_MANAGEMENT_RANKS_delete = "5.ran.de";

// Dienststellungen
export const MEMBER_MANAGEMENT_DUTY_POSITIONS_create = "5.dut.cr";
export const MEMBER_MANAGEMENT_DUTY_POSITIONS_read = "5.dut.re";
export const MEMBER_MANAGEMENT_DUTY_POSITIONS_update = "5.dut.up";
export const MEMBER_MANAGEMENT_DUTY_POSITIONS_delete = "5.dut.de";

// Ehrungen
export const MEMBER_MANAGEMENT_HONORS_create = "5.hon.cr";
export const MEMBER_MANAGEMENT_HONORS_read = "5.hon.re";
export const MEMBER_MANAGEMENT_HONORS_update = "5.hon.up";
export const MEMBER_MANAGEMENT_HONORS_delete = "5.hon.de";

// Abteilungen
export const MEMBER_MANAGEMENT_DIVISIONS_create = "5.div.cr";
export const MEMBER_MANAGEMENT_DIVISIONS_read = "5.div.re";
export const MEMBER_MANAGEMENT_DIVISIONS_update = "5.div.up";
export const MEMBER_MANAGEMENT_DIVISIONS_delete = "5.div.de";

// Konfiguration
export const MEMBER_MANAGEMENT_CONFIG_read = "5.conf.re";
export const MEMBER_MANAGEMENT_CONFIG_update = "5.conf.up";

// Stundenabrechnung
export const ACCOUNTING_TIME_SHEETS_create = "6.tim.cr";
export const ACCOUNTING_TIME_SHEETS_read = "6.tim.re";
export const ACCOUNTING_TIME_SHEETS_update = "6.tim.up";
export const ACCOUNTING_TIME_SHEETS_delete = "6.tim.de";
export const ACCOUNTING_TIME_SHEETS_read_all = "6.tim.rea";
export const ACCOUNTING_TIME_SHEETS_manage_all = "6.tim.ma";

export const ACCOUNTING_PAYROLL_REPORTS_create = "6.pre.cr";
export const ACCOUNTING_PAYROLL_REPORTS_read = "6.pre.re";
export const ACCOUNTING_PAYROLL_REPORTS_update = "6.pre.up";
export const ACCOUNTING_PAYROLL_REPORTS_delete = "6.pre.de";

export const ACCOUNTING_ARCHIVE_read = "6.arc.re";

export const ACCOUNTING_CONFIG_PAYROLL_TYPES_CREATE = "6.conf.pt.cr";
export const ACCOUNTING_CONFIG_PAYROLL_TYPES_READ = "6.conf.pt.re";
export const ACCOUNTING_CONFIG_PAYROLL_TYPES_UPDATE = "6.conf.pt.up";
export const ACCOUNTING_CONFIG_PAYROLL_TYPES_DELETE = "6.conf.pt.de";

// Finanzen
export const FINANCE = "7";

export const FINANCE_EXPENSES_create = "7.exp.cr";
export const FINANCE_EXPENSES_read = "7.exp.re";

// Admin
export const ADMINISTRATION = "9";
// Konfiguration
export const ADMINISTRATION_CONFIG_read = "9.conf.re";
// Allgemein
export const ADMINISTRATION_CONFIG_GENERAL_read = "9.conf.gen.re";
export const ADMINISTRATION_CONFIG_GENERAL_update = "9.conf.gen.up";
// Erscheinungsbild
export const ADMINISTRATION_CONFIG_APPEARANCE_read = "9.conf.ers.up";
export const ADMINISTRATION_CONFIG_APPEARANCE_update = "9.conf.ers.re";
// Organisationsstruktur (SOON)
export const ADMINISTRATION_CONFIG_INHERITANCE_read = "9.conf.inh.re";

// Abrechnung (SOON)
export const ADMINISTRATION_BILLING_read = "9.bil.re";
export const ADMINISTRATION_BILLING_update = "9.bil.up";
// Benutzer
export const ADMINISTRATION_USERS_read = "9.use.re";
// Benutzerkonten
// export const ADMINISTRATION_USERS_ACCOUNTS_create = "9.use.acc.cr"
export const ADMINISTRATION_USERS_ACCOUNTS_read = "9.use.acc.re";
// export const ADMINISTRATION_USERS_ACCOUNTS_update = "9.use.acc.up"
// export const ADMINISTRATION_USERS_ACCOUNTS_delete = "9.use.acc.de"
// Einladungen
export const ADMINISTRATION_USERS_INVITES_create = "9.use.inv.cr";
export const ADMINISTRATION_USERS_INVITES_read = "9.use.inv.re";
export const ADMINISTRATION_USERS_INVITES_update = "9.use.inv.up";
export const ADMINISTRATION_USERS_INVITES_delete = "9.use.inv.de";
// Benutzergruppen
export const ADMINISTRATION_USERS_GROUPS_create = "9.use.gro.cr";
export const ADMINISTRATION_USERS_GROUPS_read = "9.use.gro.re";
export const ADMINISTRATION_USERS_GROUPS_update = "9.use.gro.up";
export const ADMINISTRATION_USERS_GROUPS_delete = "9.use.gro.de";
// Sicherheit
export const ADMINISTRATION_SECURITY_read = "9.sec.re";
// Allgemein
export const ADMINISTRATION_SECURITY_GENERAL_read = "9.sec.gen.re";
export const ADMINISTRATION_SECURITY_GENERAL_update = "9.sec.gen.up";
// Rollen
export const ADMINISTRATION_SECURITY_ROLES_create = "9.sec.ro.cr";
export const ADMINISTRATION_SECURITY_ROLES_read = "9.sec.ro.re";
export const ADMINISTRATION_SECURITY_ROLES_update = "9.sec.ro.up";
export const ADMINISTRATION_SECURITY_ROLES_delete = "9.sec.ro.de";
// Zuordnung
// Datenschutz
export const ADMINISTRATION_PRIVACY_read = "9.dat.re";
export const ADMINISTRATION_PRIVACY_update = "9.dat.up";

// API (SOON)
export const ADMINISTRATION_SECURITY_API_read = "9.sec.api.re";
export const ADMINISTRATION_SECURITY_API_update = "9.sec.api.up";
// Erweiterungen
export const ADMINISTRATION_EXTENSIONS_read = "9.ext.re";
export const ADMINISTRATION_EXTENSIONS_manage = "9.ext.ma";
// Automation
export const ADMINISTRATION_AUTOMATION_read = "9.aut.re";

// Audit
export const ADMINISTRATION_AUDIT_read = "9.aud.re";
// Support
export const SUPPORT = "10";
export const SUPPORT_read = "10.re";
